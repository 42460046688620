import React, { useState } from 'react';
import './RequestResetPassword.css';

const RequestResetPassword = ({ apiClient }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.requestResetPassword(email, password, newPassword, confirmNewPassword);
      if (response.error) {
        setError(response.error);
      } else {
        setSuccess(true);
        setError('');
        setEmail('');
        setPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      }
    } catch (error) {
      setError('An error occurred while processing your request.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  return (
    <div className="request-reset-password-container">
      <div>
      <h2>Reset Password</h2>
      {!success ? (
        <form onSubmit={handleResetPassword}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            required
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            required
          />
          <button type="submit">Reset Password</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      ) : (
        <div className="success-message">
          <p>Password Reset Request Successful!</p>
          <p>Please check your email for further instructions.</p>
        </div>
      )}
      </div>

      <div className="additional-options">
        <div className="alt-link">
          <a href="/login">Login</a>
        </div>
        <div className="alt-link">
          <a href="/request-registration">Sign Up</a>
        </div>
      </div>

    </div>
  );
};

export default RequestResetPassword;
