import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'; // Import the caret icons

const ServicesDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (showDropdown && !event.target.closest('.services-dropdown')) {
      setShowDropdown(false);
    }
  };

  // Attach a global click event listener to close the dropdown when clicking outside
  document.addEventListener('click', handleClickOutside);

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to the parent div
    toggleDropdown();
    const { top, left, height } = event.target.getBoundingClientRect();
    setDropdownPosition({ top: top + height, left });
  };

  const options = [
    { label: 'Universal Shell', value: '/universal-shell' }, // Add URLs to each option
    { label: 'AWS Extended Shell', value: '/universal-shell-aws' }, // Add URLs to each option
    { label: 'AWS Infrastructure Builder', value: '/aws-infra-builder' }, // Add URLs to each option
  ];

  return (
    <div className="services-dropdown">
      <div className="nav-link" onClick={handleDropdownClick}>
         <span className="dropdown-toggle">
          Products &nbsp; <FontAwesomeIcon icon={showDropdown ? faCaretUp : faCaretDown} /> {/* Use FontAwesomeIcon component */}
        </span>
        {showDropdown && (
          <ul className="dropdown-menu" style={{ top: dropdownPosition.top, left: dropdownPosition.left }}>
            {options.map((option, index) => (
              <li key={index} className="dropdown-item">
                <Link to={option.value} className="nav-link">
                  {option.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ServicesDropdown;
