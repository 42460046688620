// ApiList.js
import React from 'react';
import ApiFunction from '../helper/ApiFunction';
import FunctionList from '../helper/FunctionList';
import apiList from '../helper/ApiList';

const ApiOverview = () => (
  <div>

    {/* Render the FunctionList component */}
    <FunctionList functions={apiList} />

    {apiList.map(({ name, argNames, argTypes, returnType, description, example }) => (
      <ApiFunction
        key={name}
        name={name}
        argNames={argNames}
        argTypes={argTypes}
        returnType={returnType}
        description={description}
        example={example}
      />
    ))}
  </div>
);

export default ApiOverview;
