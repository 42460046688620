import React from 'react';
import PropTypes from 'prop-types';
import './JobItem.css';

const JobItem = ({ job }) => {
  const { jobTitle, companyName, postedDate, jobLocation, jobSalary, companyIconUrl } = job;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '15%', float: 'left' }}>
        {/* Left content */}
        <img src={companyIconUrl} alt="Company Icon" className="company-icon" />
      </div>
      <div style={{ width: '25%', float: 'left' }}>
        {/* Middle content */}
        <div className="job-title"><strong>{jobTitle}</strong></div>
        <div className="company">{companyName}</div>
      </div>
      <div style={{ width: '60%', float: 'right', textAlign: 'right' }}>
        <div className="location">{jobLocation}</div>
        <div className="salary">{jobSalary}</div>
        <div className="posted-date">{postedDate}</div>
      </div>
    </div>
  );
};

JobItem.propTypes = {
  job: PropTypes.shape({
    jobTitle: PropTypes.string,
    companyName: PropTypes.string,
    postedDate: PropTypes.string,
    jobLocation: PropTypes.string,
    jobSalary: PropTypes.string,
    companyIconUrl: PropTypes.string,
  }).isRequired,
};

export default JobItem;
