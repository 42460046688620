import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Introduction from './sections/Introduction';
import Setup from './sections/Setup';
import Examples from './sections/Examples';
import ApiOverview from './sections/ApiOverview';
import ParallelProcessing from './sections/ParallelProcessing';
import './HbusDocs.css'; // Import your CSS file for styling

const HbusDocs = () => {
  const params = useParams();
  var initState = 'Introduction';
  const urlMappings = {
    'introduction': 'Introduction',
    'setup': 'Setup',
    'examples': 'Examples',
    'api-overview': 'ApiOverview',
    'parallel-processing': 'ParallelProcessing',
  };
  if (params.section != undefined && params.section in urlMappings) {
    initState = urlMappings[params.section]
  }
  const [selectedSection, setSelectedSection] = useState(initState);
  // console.log(selectedSection);

  const renderSection = () => {
    switch (selectedSection) {
      case 'Introduction':
        return <Introduction setSelectedSection={setSelectedSection}/>;
      case 'Setup':
        return <Setup />;
      case 'Examples':
        return <Examples />;
      case 'ApiOverview':
        return <ApiOverview />;
      case 'ParallelProcessing':
        return <ParallelProcessing />;
      default:
        return null;
    }
  };

  return (
    <div className="api-docs-container">
      {/* Left side contents bar */}
      <div className="contents-bar">
        <h3>Universal Shell</h3>
        <ul>
          <li className={selectedSection === 'Introduction' ? 'selected' : ''}>
            <Link to="/universal-shell/introduction" onClick={() => setSelectedSection('Introduction')}>
              Introduction
            </Link>
          </li>
          <li className={selectedSection === 'Setup' ? 'selected' : ''}>
            <Link to="/universal-shell/setup" onClick={() => setSelectedSection('Setup')}>
              Setup
            </Link>
          </li>
          <li className={selectedSection === 'Examples' ? 'selected' : ''}>
            <Link to="/universal-shell/examples" onClick={() => setSelectedSection('Examples')}>
              Examples
            </Link>
          </li>
          <li className={selectedSection === 'ApiOverview' ? 'selected' : ''}>
            <Link to="/universal-shell/api-overview" onClick={() => setSelectedSection('ApiOverview')}>
              API Overview
            </Link>
          </li>
          <li className={selectedSection === 'ParallelProcessing' ? 'selected' : ''}>
            <Link to="/universal-shell/parallel-processing" onClick={() => setSelectedSection('ParallelProcessing')}>
              Parallel Processing
            </Link>
          </li>
          <li>
            <a href="https://hypothesisbase.com/hbus_wp.pdf" target="_blank">
              White Paper
            </a>
          </li>
          <li>
            <Link to="/universal-shell-aws">
              AWS Extended Shell
            </Link>
          </li>
          <li>
            <Link to="/aws-infra-builder">
              AWS Infrastructure Builder
            </Link>
          </li>
        </ul>
      </div>

      {/* Right side content */}
      <div className="main-content">
        {renderSection()}
      </div>
    </div>
  );
};

export default HbusDocs;
