
class ApiClient {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  async validateToken(accessToken) {
    try {
      const response = await fetch(`${this.endpoint}/api/validateToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async login(inputEmail, password) {
    try {
      const response = await fetch(`${this.endpoint}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputEmail: inputEmail, password: password
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async requestRegistration(inputEmail, password) {
    try {
      const response = await fetch(`${this.endpoint}/api/requestRegistration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputEmail: inputEmail, password: password
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async requestResetPassword(inputEmail, password, newpassword) {
    try {
      const response = await fetch(`${this.endpoint}/api/requestResetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputEmail: inputEmail, password: password, newpassword: newpassword
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async requestRegistrationWithToken(authToken, inputEmail, password) {
    try {
      const response = await fetch(`${this.endpoint}/api/requestRegistrationWithToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail, password: password
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async requestResetPasswordWithToken(authToken, inputEmail, password, newpassword) {
    try {
      const response = await fetch(`${this.endpoint}/api/requestResetPasswordWithToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail, password: password, newpassword: newpassword
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async verifyRegistration(inputEmail, token) {
    try {
      const response = await fetch(`${this.endpoint}/api/verifyRegistration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputEmail: inputEmail, token: token
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async verifyResetPassword(inputEmail, token) {
    try {
      const response = await fetch(`${this.endpoint}/api/verifyResetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputEmail: inputEmail, token: token
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async addUser(authToken, inputEmail, password) {
    try {
      const response = await fetch(`${this.endpoint}/api/addUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail, password: password
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async selectUser(authToken, inputEmail) {
    try {
      const response = await fetch(`${this.endpoint}/api/selectUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async deactivateUser(authToken, inputEmail) {
    try {
      const response = await fetch(`${this.endpoint}/api/deactivateUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async reactivateUser(authToken, inputEmail) {
    try {
      const response = await fetch(`${this.endpoint}/api/reactivateUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async removeUser(authToken, inputEmail) {
    try {
      const response = await fetch(`${this.endpoint}/api/removeUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, inputEmail: inputEmail
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async listUsers(authToken) {
    try {
      const response = await fetch(`${this.endpoint}/api/listUsers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async view_profile(accessToken) {
    try {
      const response = await fetch(`${this.endpoint}/api/view_profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async update_profile(accessToken, updated_data) {
    try {
      const response = await fetch(`${this.endpoint}/api/update_profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, updated_data: updated_data
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async upload_resume(accessToken, fileName, resume_data) {
    try {
      const response = await fetch(`${this.endpoint}/api/upload_resume`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, fileName: fileName, resume_data: resume_data
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async search_jobs(accessToken, keyword, page) {
    try {
      const response = await fetch(`${this.endpoint}/api/search_jobs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, keyword: keyword, page: page
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async view_job_post(accessToken, job_id) {
    try {
      const response = await fetch(`${this.endpoint}/api/view_job_post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, job_id: job_id
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async apply_for_job(accessToken, jobId) {
    try {
      const response = await fetch(`${this.endpoint}/api/apply_for_job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, jobId: jobId
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async apply_for_job_resume_only(fileName, resumeData, jobId) {
    try {
      const response = await fetch(`${this.endpoint}/api/apply_for_job_resume_only`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fileName: fileName, resumeData: resumeData, jobId: jobId
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async submit_inquiry(accessToken, firstName, lastName, emailAddress, phoneNumber, messageContent) {
    try {
      const response = await fetch(`${this.endpoint}/api/submit_inquiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken, firstName: firstName, lastName: lastName, emailAddress: emailAddress, phoneNumber: phoneNumber, messageContent: messageContent
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }

  async post_job(authToken, job_post) {
    try {
      const response = await fetch(`${this.endpoint}/api/post_job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authToken: authToken, job_post: job_post
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Request failed');
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || 'Request failed' };
    }
  }
}

module.exports = ApiClient;

