import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Introduction.css';
import TypingText from '../../helper/TypingText';

const Introduction = (props) => {
  const navigate = useNavigate();

  const handleSearch = (e) => {
    navigate('universal-shell');
    return;
  };
  const message = "With our Universal Shell software, automation becomes effortless, freeing up time and resources for continuous innovation and advancement."
  const typedMessage = "Effortlessly Automate, Limitlessly Innovate."
  // const message = "Enterprises can optimize their workforce by automating repetitive tasks through a reliable automation pipeline, freeing up employees to focus on high-impact, innovative projects. By combining human ingenuity with efficient automation, organizations can drive continuous improvement, enhance productivity, and stay ahead in today's dynamic business landscape."

  return (
    <>
      <section className='introduction_div'>
        <div className='introduction_container'>
          <h1 style={{ marginTop : '20px', padding : '0px'}}>HypothesisBase</h1>
          <div className="introduction_text">
            <TypingText text={typedMessage} typingSpeed={40} />
          </div>
          <button className="introduction-search-button" onClick={handleSearch}>View our Universal Shell</button>
          <div className="cloud-div">
            <p className="cloud-text">We simplify the deployment and management of software systems on AWS, Azure, GCP, and on-premise environments.</p>
            <div className="cloud-logos">
              <img src="aws_logo.png" alt="AWS" className="cloud-logo" />
              <img src="azure_logo.png" alt="Azure" className="cloud-logo" />
              <img src="gcp_logo.png" alt="GCP" className="cloud-logo" />
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Introduction;
