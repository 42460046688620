import React from 'react';
import { CopyableCodeSnippet } from '../helper/CopyableCode'; // Import the CopyableCodeSnippet component

const CredentialsPathExample = ({ nodes }) => {
  // Constructing the credentials path example based on the provided list of node interfaces
  const credsPathExample = {
    "nodes" : nodes.map((node, index) => ({
      "host": node.host,
      "port": node.port,
      "user": node.user,
      "priv_key_path": node.privKeyPath
    })),
    "clusters" : {
      "cluster1" : nodes.map((node, index) => (
        `${node.user}@${node.host}:${node.port}`
      )),
    }
  };

  const strref = "{ nodes, clusters }";

  return (
    <div>
      <h2 style={{textAlign: 'left'}}>
        Parallel Processing Example
      </h2>

      <p>
        On this page, we will go through how to setup the universal shell for parallel processing.
      </p>

      <h3>Parallel Processing Credentials File</h3>

      <p>
        The credentials file needs to be updated to denote the clusters:

        <p>
          <code>creds.json = {strref}</code>
        </p>

        Each node is defined as before but a mapping of

        <p>
          <code>clusters: (cluster_name) => list(node_ref)</code>
        </p>

        is introduced. The below example shows how to define credentials for parallel processing.
      </p>
      {/* Render credentials path example using CopyableCodeSnippet component */}
      <CopyableCodeSnippet codeType={'json'} codeString={JSON.stringify(credsPathExample, null, 2)} />
      {/* Add logical commentary */}
    </div>
  );
};

const AutomationExample = () => {
  // Define example scripts
  const examples = [
    {
      description: "Example of re-deploying an application and checking its status",
      script: `# Example of re-deploying an application parallel over a cluster.
# Adjust [num_threads] accordingly

@schedule once

2@cluster1 stop_service my_app

2@cluster1 rm_file /home/user/remote/my_app.bin
2@cluster1 remote_copy_file \\
    /home/user/local/my_app.bin \\
    /home/user/remote/my_app.bin

# ensure service [my_app] does not exist
2@cluster1 delete_service my_app
2@cluster1 create_service my_app "/home/user/remote/my_app.bin"
2@cluster1 start_service my_app
2@cluster1 status_service my_app
`
    },
  ];


  return (
    <div>
      <h3>Parallel Processing Script</h3>
      <p>
        Once the

        &nbsp; <code>clusters</code> &nbsp;

        are defined in the

        &nbsp; <code>credentials.json</code> &nbsp;

        file, we can introduce parallel processing into our script.

      </p>

      <p>
        The syntax is:

        &nbsp; <code>[num_threads]@[cluster_name] [operation] [arguments]</code> &nbsp;

      </p>

      <p>
        This will instantiate

        &nbsp; <code>num_threads</code> &nbsp;

        parallel threads to complete

        &nbsp; <code>operation(arguments)</code> &nbsp;

        on

        &nbsp; <code>cluster_name</code> &nbsp;

      </p>

      {/* Render credentials path example using CopyableCodeSnippet component */}
      <CopyableCodeSnippet codeType={'shell'} codeString={examples[0].script} />
      {/* Add logical commentary */}

      <p>
        Execute the script:
      </p>

      <CopyableCodeSnippet
        codeType="shell"
        codeString="ControlNode.bin run_workflow --creds_path /path/to/credentials.json --script_path /path/to/my/workflow.sh --as_json true"
      />

    </div>
  );
};

const Examples = () => {
  // Define example node interfaces
  const nodes = [
    {
      host: "192.168.1.187",
      port: "8000",
      user: "ubuntu",
      privKeyPath: "/path/to/priv_keys/node1.pem"
    },
    {
      host: "192.168.1.188",
      port: "8000",
      user: "ubuntu",
      privKeyPath: "/path/to/priv_keys/node2.pem"
    },
  ];

  return (
    <div>
      {/* Render CredentialsPathExample component */}
      <CredentialsPathExample nodes={nodes} />
      <AutomationExample/>
    </div>
  );
};

export default Examples;
