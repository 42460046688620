import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ServicesDropdown from './helper/ServicesDropdown';
import AuthDropdown from './helper/AuthDropdown';
import './Header.css'; // Import your CSS file

const Header = ({ apiClient }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      apiClient.validateToken(accessToken)
        .then(response => {
          if (response.error) {
            setAuthenticated(false);
          } else {
            setAuthenticated(true);
            setEmail(response.success.email); // Assuming response has email field
          }
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setAuthenticated(false);
        });
    } else {
      setAuthenticated(false);
    }
  }, [apiClient]);

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    setAuthenticated(false);
  };

  const logo = '/hb_logo.png';

  const leftElem = (
    <div className="left">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <ServicesDropdown/>
      <div className="link-container">
        <Link to="/universal-shell" className="nav-link">Universal Shell</Link>
        <Link to="/search-jobs" className="nav-link">Jobs</Link>

      </div>
    </div>
  );

  const rightElem = (
    <div className="right">
      {authenticated ? (
        <AuthDropdown email={email} handleSignOut={handleSignOut}/>
      ) : (
        <Link to="/submit-inquiry" className="nav-link">Contact Us</Link>
        // <Link to="/login" className="nav-link">Login</Link>
      )}
    </div>
  );

  return (
    <header className="header">
      {leftElem}
      {rightElem}
    </header>
  );
};

export default Header;
