import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Methodology.css';

const Methodology = () => {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate('/universal-shell');
  };

  return (
    <div className="automation-solution">
      <h1>Unlock the Power of Automation with HypothesisBase</h1>
      <div className="features">
        <div className="feature">
          <span role="img" aria-label="productivity">⚙️</span>
          <p>Boost productivity by automating repetitive tasks, freeing up your workforce for strategic initiatives.</p>
        </div>
        <div className="feature">
          <span role="img" aria-label="efficiency">🔄</span>
          <p>Enhance efficiency with streamlined workflows and optimized processes across your organization.</p>
        </div>
        <div className="feature">
          <span role="img" aria-label="innovation">🚀</span>
          <p>Drive innovation by empowering employees to focus on creative endeavors and problem-solving.</p>
        </div>
        <div className="feature">
          <span role="img" aria-label="growth">🌱</span>
          <p>Foster growth with scalable automation solutions that adapt to your evolving business needs.</p>
        </div>
      </div>
      <button className="methodology-button" onClick={handleLearnMore}>Learn More</button>
    </div>
  );
};

export default Methodology;
