import React from 'react';

const CompanyLogo = ({ imageUrl }) => {
  return (
    <div
      style={{
        height: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={imageUrl}
        alt="Company Logo"
        style={{
          margin: '20px',
          maxHeight: '80%',
          maxWidth: '80%',
          borderRadius: '10px',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default CompanyLogo;
