import React, { useRef, useEffect, useState } from 'react';
import Title from './homepage/Title';
import Introduction from './introduction/Introduction';

import Affiliations from './homepage/Affiliations';
import Methodology from './homepage/Methodology';
import Solutions from './homepage/Solutions';
import Offerings from './offerings/Offerings';
import FadeInView from '../helper/FadeInView';
import SlideLeftView from '../helper/SlideLeftView';
import './Home.css';

const Home = () => {
  // return (
  //   <div className="home-container">
  //     <FadeInView threshold={0.2}>
  //       <Offerings />
  //     </FadeInView>
  //     <FadeInView threshold={0.2}>
  //       <Offerings />
  //     </FadeInView>
  //   </div>
  // );

  // return (
  //   <div className="home-container">
  //     <FadeInView threshold={0.2}>
  //       <Introduction />
  //     </FadeInView>
  //     <FadeInView threshold={0.2}>
  //       <Affiliations />
  //     </FadeInView>
  //     <FadeInView threshold={0.2}>
  //       <Methodology />
  //     </FadeInView>
  //     <FadeInView threshold={0.2}>
  //       <Offerings />
  //     </FadeInView>
  //   </div>
  // );

  return (
    <div className="home-container">
      <FadeInView threshold={0.2}>
        <Introduction />
      </FadeInView>
      <FadeInView threshold={0.2}>
        <Methodology />
      </FadeInView>
      <FadeInView threshold={0.2}>
        <Offerings />
      </FadeInView>
    </div>
  );
};

export default Home;
