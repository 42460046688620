import React, { useState } from 'react';
import './RequestRegistration.css';

const RequestRegistration = ({ apiClient }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.requestRegistration(email, password, confirmPassword);
      if (response.error) {
        setError(response.error);
      } else {
        setSuccess(true);
        setError('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      setError('An error occurred while processing your request.');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="request-registration-container">
      <div>
      <h2>Sign Up</h2>
      {!success ? (
        <form onSubmit={handleRegistration}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          <button type="submit">Register</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      ) : (
        <div className="success-message">
          <p>Registration Request Successful!</p>
          <p>Please check your email for further instructions.</p>
        </div>
      )}
      </div>

      <div className="additional-options">
        <div className="alt-link">
          <a href="/login">Login</a>
        </div>
        <div className="alt-link">
          <a href="/request-reset-pw">Reset Password</a>
        </div>
      </div>

    </div>
  );
};

export default RequestRegistration;
