import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './VerifyRegistration.css';

const VerifyRegistration = ({ apiClient }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const token = params.get('token');

  useEffect(() => {
    const verify = async () => {

      try {
        const response = await apiClient.verifyRegistration(email, token);
        if (response.error) {
          setError(response.error);
        } else {
          setSuccess(true);
        }
      } catch (error) {
        setError('An error occurred while processing your request.');
      }
    };

    verify();
  }, [apiClient, email, token]);

  return (
    <div className="verify-registration-container">
      {success ? (
        <div className="success-message">
          <p>Registration Verified Successfully!</p>
          <p>Please <a href="/login">login</a> to continue.</p>
        </div>
      ) : (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default VerifyRegistration;
