import React from 'react';
import CredentialsPathExample from '../helper/CredentialsPathExample'; // Import the CredentialsPathExample component
import AutomationExamples from '../helper/AutomationExamples';

const HbusAwsExamples = () => {
  // Define example node interfaces
  const nodes = [
    {
      host: "192.168.1.187",
      port: "8000",
      user: "ubuntu",
      privKeyPath: "/path/to/priv_keys/priv1.pem"
    },
    // Add more node interfaces as needed
  ];

  return (
    <div>
      {/* Render CredentialsPathExample component */}
      <CredentialsPathExample nodes={nodes} />
      <AutomationExamples/>
    </div>
  );
};

export default HbusAwsExamples;
