import React from 'react';
import { CopyableCodeSnippet } from './CopyableCode'; // Import the CopyableCodeSnippet component

const AutomationExamples = () => {
  // Define example scripts
  const examples = [
    {
      description: "Example of re-deploying an application and checking its status",
      script: `# Example of re-deploying an application and checking its status
@schedule once

ubuntu@192.168.1.187:8000 stop_service my_app

ubuntu@192.168.1.187:8000 rm_file /home/user/remote/my_app.bin
ubuntu@192.168.1.187:8000 remote_copy_file \\
    /home/user/local/my_app.bin \\
    /home/user/remote/my_app.bin

# ensure service [my_app] does not exist
ubuntu@192.168.1.187:8000 delete_service my_app
ubuntu@192.168.1.187:8000 create_service my_app /home/user/remote/my_app.bin
ubuntu@192.168.1.187:8000 start_service my_app
ubuntu@192.168.1.187:8000 status_service my_app
`
    },
    {
      description: "Example of Regular System Reset",
      script: `# Weekly schedule
@schedule recurring "2024/04/13 07:00:00" 604800

# Stop the service
ubuntu@192.168.1.187:8000 stop_service my_service

# Reboot the service; half hour wait
ubuntu@192.168.1.187:8000 reboot 1800

# Restart the service
ubuntu@192.168.1.187:8000 restart_service my_service

# Check the status of the service
ubuntu@192.168.1.187:8000 status_service my_service
`
    },
    {
      description: "Example of System Maintanence - Create Data Backups",
      script: `@schedule once

# Stop the critical service
ubuntu@192.168.1.187:8000 stop_service critical_service

# Create a backup of important data
ubuntu@192.168.1.187:8000 run_script backup_script.sh

# Restart the critical service
ubuntu@192.168.1.187:8000 restart_service critical_service

# Verify the service status
ubuntu@192.168.1.187:8000 status_service critical_service
`
    },
  ];

  // Define the AutomationExample component inline
  const AutomationExample = ({ iRef, description, script }) => {
    return (
      <div>
        <h3>{iRef}. {description}</h3>
        <CopyableCodeSnippet codeType={'shell'} codeString={script} />
      </div>
    );
  };

  return (
    <div>
      <h3>Example Scripts</h3>
      {examples.map((example, index) => (
        <AutomationExample
          key={index}
          iRef={index+1}
          description={example.description}
          script={example.script}
        />
      ))}
    </div>
  );
};

export default AutomationExamples;
