// FunctionList.js
import React from 'react';
import './FunctionList.css';

const FunctionListItem = ({ f_item }) => {
  let { name, argNames } = f_item
  // console.log(f_index);
  const formattedArgs = argNames.map((arg, index) => `${arg}`).join(', ');
  // const formattedArgs = argNames.map((arg, index) => `[${arg}]`).join(' ');
  return (
    <li>
      <a className="function-list-item" href={`#${name}`}>{name}({formattedArgs})</a>
    </li>
  )
};

const FunctionList = ({ functions }) => {
  // console.log(functions.map(({ name }) => name)); // Log the list of names
  return (
    <div className="function-list-container">

      <div>
      <h3>API Overview</h3> {/* Add the subtitle */}
      <p>
        This page documents the various commands available in the Universal Shell.
        Click on each method signature for sample invocations.
      </p>
      <ul className="function-list"> {/* Apply function-list class */}
        {functions.map((f_item, f_index) => (
          <FunctionListItem key={f_index} f_item={f_item}/>
        ))}
      </ul>
      </div>

    </div>
  )
};

export default FunctionList;
