import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './CopyableCode.css';

const CopyableCode = ({ codeType, snippetDesc, codeString }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div className="api-copy-container">
      <p className="api-info"><strong>{snippetDesc}</strong></p>

      <div className="api-copy">
        {codeType}
        <CopyToClipboard text={codeString} onCopy={handleCopy}>
          <span style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}>
            <FontAwesomeIcon icon={faCopy} style={{ cursor: 'pointer', marginRight: '5px' }} />
            {copied ? 'Copied!' : 'Copy'}
          </span>
        </CopyToClipboard>
      </div>

      <pre className="api-example">
        {codeString}
      </pre>
    </div>
  )
};

const CopyableCodeSnippet = ({ codeType, codeString }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div className="api-copy-container">

      <div className="api-copy">
        {codeType}
        <CopyToClipboard text={codeString} onCopy={handleCopy}>
          <span style={{ cursor: 'pointer', float: 'right', marginRight: '10px' }}>
            <FontAwesomeIcon icon={faCopy} style={{ cursor: 'pointer', marginRight: '5px' }} />
            {copied ? 'Copied!' : 'Copy'}
          </span>
        </CopyToClipboard>
      </div>

      <pre className="api-example">
        {codeString}
      </pre>
    </div>
  )
};


export { CopyableCode, CopyableCodeSnippet };
