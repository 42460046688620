import React from 'react';
import { CopyableCodeSnippet } from '../helper/CopyableCode';
import { Link } from 'react-router-dom';
import './HbusAwsIntroduction.css';

const HbusAwsIntroduction = ({ setSelectedSection }) => {
  const copyText = (text) => {
    alert(`Copied: ${text}`);
  };

  const sampleCredsExample = `us-east-2
AKIA...
QmS2...`;

  const sampleCreds = `[region_name]
[aws_access_key_id]
[secret_access_key]
...repeated for each region
`;

  const sampleScript = `
# example of deploying and managing an application
@schedule once

ubuntu@192.168.1.251:8000 run_cmd "bash /home/build/my/app.sh" 80

root@i-056e009e77195aba5.pub_ipv4:8000 remote_copy_dir \\
    /home/my/app/build \\
    /home/ubuntu/my_app

# ensure service [my_app] does not exist
root@i-056e009e77195aba5.pub_ipv4:8000 delete_service my_app
root@i-056e009e77195aba5.pub_ipv4:8000 create_service my_app \\
  "/home/ubuntu/my_app/my_app.bin"
root@i-056e009e77195aba5.pub_ipv4:8000 start_service my_app
root@i-056e009e77195aba5.pub_ipv4:8000 status_service my_app
`;

  return (
    <div className="introduction-container">
      <h1>AWS Extended Universal Shell</h1>

      <div className="introduction-text">
        The AWS Extended Universal Shell (AWS EUS) is a simple extension of the Universal Shell.
      </div>

      <div className="introduction-text">
        The key distinction is that a node can be identified by the expression:
        <CopyableCodeSnippet
          codeType="shell"
          codeString={`[user]@[instance_id].(pub_ipv4|priv_ipv4):[port]`}
          onCopy={copyText}
        />
      </div>

      <div className="introduction-text">
        in addition to the expression:
        <CopyableCodeSnippet
          codeType="shell"
          codeString={`[user]@[host]:[port]`}
          onCopy={copyText}
        />
      </div>

      <div className="introduction-text">
        within the universal shell script.
      </div>

      <div className="introduction-text">
        With AWS EUS, you can seamlessly deploy and manage your workload on AWS environments.
      </div>

      <div className="introduction-text">
        This documentation page covers the following subject matter:
        <ul>
          <li>
            How to get started with using the software:
            &nbsp;<a href="#quick-start">Quick Start</a>
          </li>

          <li>
            Comprehensive API reference (same as Universal Shell):
            &nbsp;<Link to="/universal-shell/api-overview" onClick={() => setSelectedSection('ApiOverview')}>
              API Overview
            </Link>
          </li>
        </ul>
      </div>

      <h2 id="quick-start">Quick Start</h2>
      <ol>
        <li>
          Define AWS credentials for each region in the following format:
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleCredsExample}
            onCopy={copyText}
          />
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleCreds}
            onCopy={copyText}
          />
        </li>
        <li>
          Define a cluster credentials file which defines the authentication information for each node:
          <CopyableCodeSnippet
            codeType="json"
            codeString={`
[
  {
    "host": "192.168.1.251",
    "port": "8000",
    "user": "ubuntu",
    "priv_key_path": "/path/to/my/local/key.pem"
  },
  {
    "instance_id": "i-056e009e77195aba5",
    "port": "8000",
    "user": "root",
    "priv_key_path": "/path/to/my/aws/instance/key.pem"
  }
]
            `}
            onCopy={copyText}
          />
        </li>
        <li>
          Define your shell script for deployment and management tasks:
          <CopyableCodeSnippet
            codeType="shell"
            codeString={sampleScript}
            onCopy={copyText}
          />
        </li>
        <li>
          Execute the shell script using the provided command:
          <CopyableCodeSnippet
            codeType="shell"
            codeString={`
AwsControlNode.bin run_workflow \\
  --aws_creds /path/to/aws_credentials.key \\
  --cluster_creds /path/to/cluster_creds.json \\
  --script_path /path/to/script.sh \\
  --as_json false
            `}
            onCopy={copyText}
          />
        </li>
      </ol>

      <h2>AWS EUS CLI API</h2>
      <p className="api-description">
        The AWS EUS CLI API provides various functionalities for orchestrating shell scripts on a distributed cluster.
      </p>
      <ul className="api-list">
        <li>
          <span className="api-command">run_workflow</span>: Run shell scripts on a distributed cluster.
          <CopyableCodeSnippet
            codeType="shell"
            codeString={`AwsControlNode.bin run_workflow [-h] \\
  --aws_creds /path/to/aws_credentials.key \\
  --cluster_creds /path/to/cluster_creds.json \\
  --script_path /path/to/script.sh \\
  --as_json false
            `}
            onCopy={copyText}
          />
        </li>
        <li>
          <span className="api-command">print_schedule</span>: Print the schedule of workflows from a specified file.
          <CopyableCodeSnippet
            codeType="shell"
            codeString={`AwsControlNode.bin print_schedule [-h] \\
  --aws_creds /path/to/aws_credentials.key \\
  --cluster_creds /path/to/cluster_creds.json \\
  --script_path /path/to/script.sh \\
  --as_json false
            `}
            onCopy={copyText}
          />
        </li>
        <li>
          <span className="api-command">gen_key_pair</span>: Generate a key pair for authentication.
          <CopyableCodeSnippet
            codeType="shell"
            codeString={`AwsControlNode.bin gen_key_pair [-h] \\
  --pub_key_path /path/to/pub.key \\
  --priv_key_path /path/to/priv.key
            `}
            onCopy={copyText}
          />
        </li>
      </ul>
    </div>
  );
};

export default HbusAwsIntroduction;
