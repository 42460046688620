// SearchBar.js
import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ onSearch, message }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = () => {
        onSearch(searchQuery);
    };

    return (
        <div>
        <div className="search-bar">
            <input
                type="text"
                placeholder="Enter search query"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
                style={{marginBottom : '5px'}}
            />
            <button className="search-button" onClick={handleSearch}
              style={{marginBottom : '5px'}}>Search</button>
        </div>
        <div className="search-message">{message}</div>
        </div>
    );
};

export default SearchBar;
