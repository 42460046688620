import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import HbusAwsIntroduction from './aeus_sections/HbusAwsIntroduction';
import HbusAwsExamples from './aeus_sections/HbusAwsExamples';
import './HbusWithAws.css'; // Import your CSS file for styling

const HbusWithAws = () => {
  const params = useParams();
  var initState = 'Introduction';
  const urlMappings = {
    'introduction': 'Introduction',
    'examples': 'Examples',
  };
  if (params.section != undefined && params.section in urlMappings) {
    initState = urlMappings[params.section]
  }
  const [selectedSection, setSelectedSection] = useState(initState);
  // console.log(selectedSection);

  const renderSection = () => {
    switch (selectedSection) {
      case 'Introduction':
        return <HbusAwsIntroduction setSelectedSection={setSelectedSection}/>;
      case 'Examples':
        return <HbusAwsExamples />;
      default:
        return null;
    }
  };

  return (
    <div className="api-docs-container">
      {/* Left side contents bar */}
      <div className="contents-bar">
        <h3>AWS Extended Universal Shell</h3>
        <ul>
          <li className={selectedSection === 'Introduction' ? 'selected' : ''}>
            <Link to="/universal-shell-aws/introduction" onClick={() => setSelectedSection('Introduction')}>
              Introduction
            </Link>
          </li>
          <li>
            <Link to="/universal-shell">
              Universal Shell
            </Link>
          </li>
        </ul>
      </div>

      {/* Right side content */}
      <div className="main-content">
        {renderSection()}
      </div>
    </div>
  );
};

export default HbusWithAws;
