import React from 'react';
import { CopyableCodeSnippet } from '../helper/CopyableCode'; // Import the CopyableCodeSnippet component
import './Setup.css'; // Import CSS file for styling

const Setup = () => {
  // Define variables for Linux and Windows setup commands
  const linuxSetup = `# on ManagedNode server
# /home/ubuntu/ManagedNode_1.0.2_gl_2.27 = path to base directory of ManagedNode

managedNodeDir="/home/ubuntu/ManagedNode_1.0.2_gl_2.27"
tgtUser="root"
tgtHost="0.0.0.0"
tgtPort="8000"

chmod +x $\{managedNodeDir\}/exe/ManagedNode.bin

sudo sh -c "
cat << EOF > /etc/systemd/system/ManagedNode.service

[Unit]
Description=service definition for HypothesisBase universal shell ManagedNode

[Service]
User=$\{tgtUser\}
ExecStart=$\{managedNodeDir\}/exe/ManagedNode.bin --host $\{tgtHost\} --port $\{tgtPort\} --work_dir $\{managedNodeDir\}/work --authorized_keys_dir $\{managedNodeDir\}/authorized_keys
KillMode=process

[Install]
WantedBy=multi-user.target

EOF
"

sudo systemctl daemon-reload
sudo systemctl enable ManagedNode.service
sudo systemctl restart ManagedNode.service
`;


  const winBat = `
:: ON Windows:
:: C:\path\toManagedNode = path to base directory of ManagedNode

set managedNodeDir=C:\path\toManagedNode
set tgtUser=system
set tgtHost=0.0.0.0
set tgtPort=8000

schtasks /create /tn ManagedNodeService /sc onstart /ru %tgtUser% /tr "%managedNodeDir%\exe\ManagedNode.exe --host %tgtHost% --port %tgtPort% --work_dir %managedNodeDir%\work --authorized_keys_dir %managedNodeDir%\authorized_keys"
schtasks /run /tn ManagedNodeService
`;

  const winStart = `
schtasks /run /tn ManagedNode
`;

  const winTaskConfig = `<?xml version="1.0" encoding="UTF-16"?>
<Task version="1.2" xmlns="http://schemas.microsoft.com/windows/2004/02/mit/task">
  <RegistrationInfo>
    <Date>2024-06-14T22:37:28.8353033</Date>
    <Author>administrator</Author>
    <URI>\\ManagedNode</URI>
  </RegistrationInfo>
  <Triggers>
    <BootTrigger>
      <Enabled>true</Enabled>
    </BootTrigger>
  </Triggers>
  <Principals>
    <Principal id="Author">
      <UserId>administrator</UserId>
      <LogonType>Password</LogonType>
      <RunLevel>LeastPrivilege</RunLevel>
    </Principal>
  </Principals>
  <Settings>
    <MultipleInstancesPolicy>IgnoreNew</MultipleInstancesPolicy>
    <DisallowStartIfOnBatteries>false</DisallowStartIfOnBatteries>
    <StopIfGoingOnBatteries>false</StopIfGoingOnBatteries>
    <AllowHardTerminate>true</AllowHardTerminate>
    <StartWhenAvailable>false</StartWhenAvailable>
    <RunOnlyIfNetworkAvailable>false</RunOnlyIfNetworkAvailable>
    <IdleSettings>
      <StopOnIdleEnd>false</StopOnIdleEnd>
      <RestartOnIdle>false</RestartOnIdle>
    </IdleSettings>
    <AllowStartOnDemand>true</AllowStartOnDemand>
    <Enabled>true</Enabled>
    <Hidden>false</Hidden>
    <RunOnlyIfIdle>false</RunOnlyIfIdle>
    <WakeToRun>false</WakeToRun>
    <ExecutionTimeLimit>PT0S</ExecutionTimeLimit>
    <Priority>7</Priority>
  </Settings>
  <Actions Context="Author">
    <Exec>
      <Command>C:\\ManagedNodeWin\\exe\\ManagedNode.exe</Command>
      <Arguments>--host 0.0.0.0 --port 8000 --work_dir C:\\ManagedNodeWin\\work --authorized_keys_dir C:\\ManagedNodeWin\\authorized_keys</Arguments>
    </Exec>
  </Actions>
</Task>
`;

  return (
    <div className="setup-container">
      <h2>Setup Instructions</h2>
      <p className="setup-description">
        The ManagedNode software needs to be set up as an on-boot process on the target machine. Below are the setup instructions for both Linux and Windows:
      </p>

      <div className="setup-instructions">
        <h3>On Linux:</h3>
        <p style={{ width : '80%'}}>
          1. Run the below setup script with root privileges once
          the ManagedNode directory has been setup. Confirm the configuration.
        </p>

        <CopyableCodeSnippet codeType={'shell'} codeString={linuxSetup} />

        <h3>On Windows:</h3>
        <p style={{ width : '80%'}}>
          1. Copy the XML task configuration into: <code>ManagedNode.xml</code> file.
          Make sure the <code>{"<Command>"}</code> and <code>{"<Arguments>"}</code>
          &nbsp; nodes are correct.
        </p>
        <CopyableCodeSnippet codeType={'xml'} codeString={winTaskConfig} />

        <p style={{ width : '80%'}}>
          2. Open the Task Scheduler Application. Click <code>Import Task</code> and
          select the <code>ManagedNode.xml</code> file.
          Confirm the Options and create the Task by clicking OK.
          You may be prompted for password to create task.
        </p>

        <p style={{ width : '80%'}}>
          3. Open the terminal with appropriate privileges, and run the task through
          the following command.
        </p>
        <CopyableCodeSnippet codeType={'bat'} codeString={winStart} />

      </div>
    </div>
  );
};

export default Setup;
