import React from 'react';
import { CopyableCodeSnippet } from './CopyableCode'; // Import the CopyableCodeSnippet component

const CredentialsPathExample = ({ nodes }) => {
  // Constructing the credentials path example based on the provided list of node interfaces
  const credsPathExample = nodes.map((node, index) => ({
    "host": node.host,
    "port": node.port,
    "user": node.user,
    "priv_key_path": node.privKeyPath
  }));

  return (
    <div>
      <h3>Credentials Path Example</h3>
      <p>This example demonstrates how to define a credentials file on your local machine as a JSON file representing a list of nodes in your cluster.</p>
      {/* Render credentials path example using CopyableCodeSnippet component */}
      <CopyableCodeSnippet codeType={'json'} codeString={JSON.stringify(credsPathExample, null, 2)} />
      {/* Add logical commentary */}
    </div>
  );
};

export default CredentialsPathExample;
