import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const AuthDropdown = ({ email, handleSignOut }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];

  return (
    <div className="authenticated" onClick={toggleDropdown}>
      <span>{email}</span>
      <span className="caret">&#9660;</span> {/* Downward caret */}
      {showDropdown && (
        <div className="dropdown">
          <Link to="/view-profile" className="dropdown-item">View Profile</Link>
          <Link to="/edit-profile" className="dropdown-item">Update Profile</Link>
          <li onClick={handleSignOut} className="dropdown-item">Log Out</li>
        </div>
      )}
    </div>
  )

};

export default AuthDropdown;
