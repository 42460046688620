import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import './VerifyResetPassword.css';

const VerifyResetPassword = ({ apiClient }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const { email, token } = useParams();

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await apiClient.verifyResetPassword(email, token);
        if (response.error) {
          setError(response.error);
        } else {
          setSuccess(true);
        }
      } catch (error) {
        setError('An error occurred while processing your request.');
      }
    };

    verify();
  }, [apiClient, email, token]);

  return (
    <div className="verify-reset-password-container">
      {success ? (
        <div className="success-message">
          <p>Password Reset Verified Successfully!</p>
          <p>Please <a href="/login">login</a> with your new password.</p>
        </div>
      ) : (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default VerifyResetPassword;
