import React from 'react';
import { Link } from 'react-router-dom';
import JobItem from './JobItem';
import './JobItemList.css';

const JobItemList = ({ jobs }) => {
  return (
    <div className="job-list-container">
      {jobs.map(job => (
        <Link to={'/job/' + job.jobId} target="_blank" key={job.jobId} className="job-item-link">
          <JobItem job={job} />
        </Link>
      ))}
    </div>
  );
};

export default JobItemList;
