import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SlideLeftView = ({ children, threshold }) => {
  const [isVisible, setIsVisible] = useState(false);
  const slideRef = useRef(null);

  const checkVisibility = () => {
    if (!slideRef.current) return;

    const slideRect = slideRef.current.getBoundingClientRect();
    const slideLeft = slideRect.left;
    const viewportWidth = window.innerWidth;

    setIsVisible(slideLeft < viewportWidth * (1 - threshold) && slideLeft + slideRect.width > 0);
  };

  useEffect(() => {
    const scrollHandler = () => {
      checkVisibility();
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    checkVisibility();
  }, [children, threshold]);

  return (
    <div
      ref={slideRef}
      style={{
        transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
        transform: `translateX(${isVisible ? '0%' : '-100%'})`, // Slide from left to right or hide by moving out of view
        opacity: isVisible ? 1 : 0, // Hide the element when not visible
      }}
    >
      {children}
    </div>
  );
};

SlideLeftView.propTypes = {
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number.isRequired, // Value between 0 and 1 indicating the percentage of the component that should be visible to trigger the animation
};

SlideLeftView.defaultProps = {
  threshold: 0.5, // Default threshold value is set to 0.5 (50%)
};

export default SlideLeftView;
