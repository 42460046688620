// ApiFunction.js
import React, { useState } from 'react';
import { CopyableCode, CopyableCodeSnippet } from './CopyableCode';
import './ApiFunction.css';

function escapeShellArg(val) {
    return '"' + String(val).replace(/(["\s'$`\\])/g, '\\$1') + '"';
}


const ApiFunction = ({ name, argNames, argTypes, returnType, description, example }) => {
  const apiReq = {
    operation: name,
    parameters: example
  };

  const formattedArgs = argNames.map((arg, index) => `${arg}: ${argTypes[index]}`).join(', ');
  // const usageStr = `[user]@[host]:[port] ${name} ` + argNames.map((arg, index) => `${arg}: ${argTypes[index]}`).join(', ');
  // const codeString = JSON.stringify(apiReq, null, 2);
  const usageStr = `[user]@[host]:[port] ${name} ` + argNames.map((arg, index) => `[${arg}]`).join(' ');
  const exampleStr = `ubuntu@192.168.1.187:8000 ${name} ` + Object.entries(example).map( ([argName, argVal]) => {
    // Map the value here
    // return [key, value.toUpperCase()]; // Example: converting to uppercase
    // return `${escapeShellArg(argVal)}`;
    return `${argVal}`;
  }).join(' ');

  return (
    <div id={name} className="api-function">
      <h3>{`${name}(${formattedArgs}) -> ${returnType}`}</h3>
      <p className="api-description">{description}</p>
      <CopyableCode codeType={"shell"} snippetDesc={'Command Usage:'} codeString={usageStr}/>
      <CopyableCode codeType={"shell"} snippetDesc={'Command Example:'} codeString={exampleStr}/>
    </div>
  )
};

export default ApiFunction;
