import React from "react"
import './FeaturedServices.css'

import { FaCogs, FaServer, FaCode } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ServiceItems = () => {
  const navigate = useNavigate();

  let iconStyle = {
    'height' : '50px',
    'padding' : '5px',
  };

  const services = [
    {
      index: 0,
      name: 'Custom Automation Pipelines',
      desc: `Implement custom automation pipelines through our usable, portable, and secure Universal Shell, tailored to your business specific use case.`,
      icon: <FaCogs style={iconStyle} size="2x" />
    },
    {
      index: 1,
      name: 'Enterprise IT Migration/Deployment/Refactor',
      desc: `Seamlessly migrate, deploy, or refactor enterprise IT environments with our expert team, ensuring a smooth transition and optimized performance.`,
      icon: <FaServer style={iconStyle} size="2x" />
    },
    {
      index: 2,
      name: 'Custom Automation Solutions',
      desc: `Create custom solutions in the automation space to address unique challenges and streamline operations, maximizing efficiency and productivity.`,
      icon: <FaCode style={iconStyle} size="2x" />
    }
  ];

  const handleLearnMore = () => {
    navigate('services-details');
  };

  return (
    <>
      {services.map(service => (
        <div className='service-item' key={service.index}>
          {service.icon}
          <h4>{service.name}</h4>
          <p style={{ padding: '10px' }}>{service.desc}</p>
        </div>
      ))}
    </>
  );
};

const FeaturedServices = () => {
  return (
      <div className='services_container'>
        <ServiceItems/>
      </div>
  );
};

export default FeaturedServices;
