import React from "react";
import "./Offerings.css";
import { Link, useNavigate } from 'react-router-dom';
import FeaturedServices from "./FeaturedServices"

const Offerings = (props) => {
  const navigate = useNavigate();
  // <FeaturedServices/>
  const handleContact = () => {
    navigate('/contact-us');
    return;
  };
  return (
      <div className='offering_div'>
        <div className='offering_container'>
          <p className='offering_title'><b>Our Services</b></p>
          <FeaturedServices/>
          <div className="offering_button">
            <button className="contact-button" onClick={handleContact}>Contact Us</button>
          </div>
        </div>
      </div>
  );
}

export default Offerings
