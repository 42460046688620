import { useState, useEffect, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import JobItemList from '../helper/JobItemList';
import SearchBar from '../helper/SearchBar';
import './SearchJobs.css';

class SearchJobs extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      data: [],
      searchTerm: '',
      currentPage: 1,
      totalPages: 0,
      totalMatches: 0,
      totalCount: 0,
    };
    this.searchTerm = '';
    this.currentPage = 1;
    this.fetchEmails = this.fetchEmails.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.fetchEmails();
  }

  async fetchEmails() {
    try {
      let accessToken = localStorage.getItem('accessToken');
      let searchTerm = this.searchTerm;
      let currentPage = this.currentPage;
      const response = await this.props.apiClient.search_jobs(accessToken, searchTerm, currentPage);
      this.setState({
        data: response.data,
        searchTerm: searchTerm,
        currentPage: currentPage,
        totalPages: response.totalPages,
        totalMatches: response.totalMatches,
        totalCount: response.totalCount,
      })
    } catch (error) {
      console.error('Error fetching mails:', error);
    }
  }

  handlePageChange(page) {
    if (page < 1 || page > this.state.totalPages) { return; }
    console.log(`page change`);
    this.currentPage = page;
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: adds smooth scrolling animation
    });
    this.fetchEmails();
    return;
  }

  handleSearch(searchTerm) {
    this.searchTerm = searchTerm
    // reset current page to 1
    this.currentPage = 1;
    this.fetchEmails()
    return;
  }

  render() {
    let currentPage = this.state.currentPage;
    let totalPages = this.state.totalPages;
    let data = this.state.data || [];
    let totalMatches = this.state.totalMatches;
    var message = `${this.state.totalCount} jobs saved locally`

    if (this.state.searchTerm.length > 0) {
      message = `${this.state.totalMatches} job(s) matched for query: \"${this.state.searchTerm}\"`;
    }
    else {
      message = `${this.state.totalCount} job(s)`;
    }

    return (
        <div className="search-jobs">
          <h2 className="search-header">Search Jobs</h2>
            <SearchBar onSearch={this.handleSearch} message={message}/>
            <div className="search-results-info">
              <JobItemList jobs={data} />
            </div>
            <div className="pagination">
                <button onClick={() => this.handlePageChange(currentPage - 1)}>Previous</button>
                <pre> {currentPage} / {totalPages} </pre>
                <button onClick={() => this.handlePageChange(currentPage + 1)}>Next</button>
            </div>
        </div>
    );

  }

}

export default SearchJobs;
