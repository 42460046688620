import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ apiClient }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const verify = async () => {
      try {
        const response = await apiClient.validateToken(accessToken);
        if (!response.error) {
          navigate('/view-profile')
        }
      } catch (error) {}
    };

    if (accessToken) {
      verify();
    }
  }, [apiClient]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.login(email, password);
      localStorage.setItem('accessToken', response.accessToken);
      window.location.href = '/home';
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && <div className="error-message">{error}</div>}
          <button type="submit">Login</button>
        </form>
      </div>
      <div className="additional-options">
        <div className="alt-link">
          <a href="/request-registration">Sign Up</a>
        </div>
        <div className="alt-link">
          <a href="/request-reset-pw">Reset Password</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
