import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewProfile.css';

const ViewProfile = ({ apiClient }) => {
  const navigate = useNavigate();
  const [userModel, setUserModel] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found');
        }
        const userData = await apiClient.view_profile(accessToken);
        setUserModel(userData);
      } catch (error) {
        setError(error.message);
        navigate('/login');
      }
    };

    fetchUserProfile();
  }, [apiClient, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!userModel) {
    return <div>Loading...</div>;
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    citizenshipStatus,
    description,
    gender,
    ethnicity,
    resumeName,
    resumeUrl
  } = userModel;

  const handleEditProfileClick = () => {
    navigate('/edit-profile');
  };

  const genderOptions = ['male', 'female', 'non-binary', 'prefer not to answer'];
  const ethnicityOptions = [
    'Asian or Asian American',
    'Black or African American',
    'Hispanic or Latine',
    'Indigenous or Native American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Other',
    'I prefer not to answer'
  ];

  let accessToken = localStorage.getItem('accessToken');
  const resumeLink = `http://127.0.0.1:8085/api/viewAttachment/${accessToken}/${resumeUrl}`;

  return (
    <div className="profile-container">
      <h2>View Profile</h2>
      <form className="profile-form">
        <label>First Name:</label>
        <input type="text" value={firstName} readOnly />
        <label>Last Name:</label>
        <input type="text" value={lastName} readOnly />
        <label>Phone Number:</label>
        <input type="text" value={phoneNumber} readOnly />
        <label>Email Address:</label>
        <input type="text" value={emailAddress} readOnly />
        <label>Citizenship Status:</label>
        <input type="text" value={citizenshipStatus} readOnly />
        <label>Description:</label>
        <textarea style={{ resize: 'none' }} value={description} readOnly />
        <label>Gender:</label>
        <select value={gender} disabled>
          {genderOptions.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <label>Ethnicity:</label>
        <select value={ethnicity} disabled>
          {ethnicityOptions.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <label>Resume:</label>
        <div className="resume-link">
          <a href={resumeLink} target="_blank">{resumeName}</a>
        </div>
      </form>
      <button onClick={handleEditProfileClick}>Edit Profile</button>
    </div>
  );
};

export default ViewProfile;
