import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './EditProfile.css';

const EditProfile = ({ apiClient }) => {
  const navigate = useNavigate();
  const [userModel, setUserModel] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token not found');
        }
        const userData = await apiClient.view_profile(accessToken);
        setUserModel(userData);
      } catch (error) {
        setError(error.message);
        navigate('/login');
      }
    };

    fetchUserProfile();
  }, [apiClient, navigate]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const accessToken = localStorage.getItem('accessToken');
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const response = await apiClient.upload_resume(accessToken, file.name, e.target.result);
        setUserModel({ ...userModel, resumeName: file.name, resumeUrl : response.url });
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await handleSubmission();
  };

  const handleSubmission = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await apiClient.update_profile(accessToken, userModel);
      navigate('/view-profile');
    } catch (error) {
      setError(error.message);
    }
  }

  const handleCancel = () => {
    navigate('/view-profile');
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!userModel) {
    return <div>Loading...</div>;
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    citizenshipStatus,
    description,
    gender,
    ethnicity,
    resumeName,
    resumeUrl
  } = userModel;

  const genderOptions = ['male', 'female', 'non-binary', 'prefer not to answer'];
  const ethnicityOptions = [
    'Asian or Asian American',
    'Black or African American',
    'Hispanic or Latine',
    'Indigenous or Native American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Other',
    'I prefer not to answer'
  ];

  let accessToken = localStorage.getItem('accessToken');
  const resumeLink = `http://127.0.0.1:8085/api/viewAttachment/${accessToken}/${resumeUrl}`;

  return (
    <div className="profile-container">
      <h2>Edit Profile</h2>
      <form className="profile-form" onSubmit={handleFormSubmit}>
        <label>First Name:</label>
        <input type="text" value={firstName} onChange={(e) => setUserModel({ ...userModel, firstName: e.target.value })} />
        <label>Last Name:</label>
        <input type="text" value={lastName} onChange={(e) => setUserModel({ ...userModel, lastName: e.target.value })} />
        <label>Phone Number:</label>
        <input type="text" value={phoneNumber} onChange={(e) => setUserModel({ ...userModel, phoneNumber: e.target.value })} />
        <label>Email Address:</label>
        <input type="text" value={emailAddress} onChange={(e) => setUserModel({ ...userModel, emailAddress: e.target.value })} />
        <label>Citizenship Status:</label>
        <input type="text" value={citizenshipStatus} onChange={(e) => setUserModel({ ...userModel, citizenshipStatus: e.target.value })} />
        <label>Description:</label>
        <textarea style={{ resize: 'none' }} value={description} onChange={(e) => setUserModel({ ...userModel, description: e.target.value })} />
        <label>Gender:</label>
        <select value={gender} onChange={(e) => setUserModel({ ...userModel, gender: e.target.value })}>
          {genderOptions.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <label>Ethnicity:</label>
        <select value={ethnicity} onChange={(e) => setUserModel({ ...userModel, ethnicity: e.target.value })}>
          {ethnicityOptions.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
        <label>Resume:</label>
        <div>
          <input type="file" onChange={handleFileChange} />
        </div>
        <div className="resume-link">
          <a href={resumeLink} target="_blank">{resumeName}</a>
        </div>
      </form>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button className="submit-button" onClick={handleSubmission}>Submit</button>
        <button className="cancel-button" onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default EditProfile;
