import React from 'react';
import Offerings from './offerings/Offerings';
import { useNavigate } from 'react-router-dom';
import './Products.css';

const Products = () => {
  const navigate = useNavigate();

  const handleInquiryClick = () => {
    navigate('/submit-inquiry');
  };

  return (
    <div className="products-container">
      <Offerings/>
    </div>
  );
}

export default Products;
