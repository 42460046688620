import React from 'react';
import { CopyableCodeSnippet } from '../helper/CopyableCode';
import { Link } from 'react-router-dom';
import './Introduction.css';

const Introduction = ({ setSelectedSection }) => {
  const copyText = (text) => {
    alert(`Copied: ${text}`);
  };

  const sampleCreds = `[
  {
    "host": "192.168.1.187",
    "port": "8000",
    "user": "ubuntu",
    "priv_key_path": "/path/to/priv_keys/priv1.pem"
  }
]`

  const sampleWf = `
# example of re-deploying an application and checking its status
@schedule once

ubuntu@192.168.1.187:8000 stop_service my_app

ubuntu@192.168.1.187:8000 rm_file /home/user/remote/my_app.bin
ubuntu@192.168.1.187:8000 remote_copy_file \\
    /home/user/local/my_app.bin \\
    /home/user/remote/my_app.bin

# ensure service [my_app] does not exist
ubuntu@192.168.1.187:8000 delete_service my_app
ubuntu@192.168.1.187:8000 create_service my_app /home/user/remote/my_app.bin
ubuntu@192.168.1.187:8000 start_service my_app
ubuntu@192.168.1.187:8000 status_service my_app

`

  return (
    <div className="introduction-container">
      <h1>HypothesisBase Universal Shell</h1>

      <p className="introduction-text">
        The HypothesisBase Universal Shell (H.B.U.S.) is a simple, scalable, and portable automation
        tool to solve automation challenges of enterprises managing software systems.
      </p>

      <div className="introduction-text">
        H.B.U.S. is distributed as a pair of executable software(s) (ManagedNode, ControlNode), wherein:
        <ul>
          <li>[ManagedNode] software is ran on the target machine(s)</li>
          <li>[ControlNode] software orchestrates workflows on those machine(s)</li>
        </ul>
      </div>

      <p className="introduction-text">
        H.B.U.S. offers a comprehensive suite of ManagedNode and ControlNode
        executables, achieving seamless integration with both
        Linux and Windows environments at any scale.
      </p>

      <p className="introduction-text">
        With its seamless deployment, H.B.U.S. enables organizations to effortlessly build scalable and secure automation pipelines,
        empowering them to streamline their workflows and achieve unprecedented levels of efficiency.
      </p>

      <div className="introduction-text">
        This documentation page covers the following subject matter:
        <ul>

          <li>
            How to get started with using the software:
            &nbsp;<a href="#quick-start">Quick Start</a>
          </li>

          <li>
            How to setup the ManagedNode's for production use:
            &nbsp;<Link to="/universal-shell/setup" onClick={() => setSelectedSection('Setup')}>
              Setup
            </Link>
          </li>

          <li>
            Use cases with Example Scripts:
            &nbsp;<Link to="/universal-shell/examples" onClick={() => setSelectedSection('Examples')}>
              Examples
            </Link>
          </li>

          <li>
            Comprehensive API reference:
            &nbsp;<Link to="/universal-shell/api-overview" onClick={() => setSelectedSection('ApiOverview')}>
              Api Overview
            </Link>
          </li>
        </ul>
      </div>

      <h2 id="quick-start">Quick Start</h2>
      <ol>
        <li>
          Generate RSA keys for authentication:
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ControlNode.bin gen_key_pair --pub_key_path pub_keys/pub1.pem --priv_key_path priv_keys/priv1.pem"
            onCopy={copyText}
          />
        </li>
        <li>
          Store <code>pub_keys/pub1.pem</code> on the target server:
          <CopyableCodeSnippet
            codeType="shell"
            codeString="scp pub_keys/pub1.pem user@target_server:/path/to/authorized_keys/pub1.pem"
            onCopy={copyText}
          />
        </li>
        <li>
          Start the ManagedNode server:
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ManagedNode.bin --host 0.0.0.0 --port 8000 --work_dir work --authorized_keys_dir authorized_keys"
            onCopy={copyText}
          />
        </li>
        <li>
          Define a credentials file on your local machine as a JSON file: &nbsp;
          <code className="code-block">/path/to/credentials.json</code>
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleCreds}
            onCopy={copyText}
          />
        </li>
        <li>
          Define the workflow using shell-like syntax: &nbsp;
          <code className="code-block">/path/to/my/workflow.sh</code>
          <CopyableCodeSnippet
            codeType="shell"
            codeString={sampleWf}
            onCopy={copyText}
          />
        </li>
        <li>
          Execute a workflow using the command:
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ControlNode.bin run_workflow --creds_path /path/to/credentials.json --script_path /path/to/my/workflow.sh --as_json true"
            onCopy={copyText}
          />
        </li>
      </ol>

      <h2>ControlNode CLI API</h2>
      <p className="api-description">
        The ControlNode CLI API offers various functionalities:
      </p>
      <ul className="api-list">
        <li>
          <span className="api-command">run_workflow</span>: Run workflows from a specified file.
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ControlNode.bin run_workflow [-h] --creds_path CREDS_PATH --script_path SCRIPT_PATH [--log_file LOG_FILE] [--as_json {true,false}]"
            onCopy={copyText}
          />
        </li>
        <li>
          <span className="api-command">print_schedule</span>: Print the schedule of workflows from a specified file.
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ControlNode.bin print_schedule [-h] --creds_path CREDS_PATH --script_path SCRIPT_PATH [--log_file LOG_FILE] [--as_json {true,false}]"
            onCopy={copyText}
          />
        </li>
        <li>
          <span className="api-command">gen_key_pair</span>: Generate a key pair for authentication.
          <CopyableCodeSnippet
            codeType="shell"
            codeString="ControlNode.bin gen_key_pair [-h] --pub_key_path PUB_KEY_PATH --priv_key_path PRIV_KEY_PATH"
            onCopy={copyText}
          />
        </li>
      </ul>

      <h2>ManagedNode CLI API</h2>
      <p className="api-description">
        The ManagedNode CLI API allows you to host services for remote management.
      </p>
      <div className="api-example">
        Example:
        <CopyableCodeSnippet
          codeType="shell"
          codeString="ManagedNode.bin [-h] --host HOST --port PORT --work_dir WORK_DIR --authorized_keys_dir AUTHORIZED_KEYS_DIR"
          onCopy={copyText}
        />
      </div>
    </div>
  );
};

export default Introduction;
