import React from 'react';
import { CopyableCodeSnippet } from '../helper/CopyableCode';
import { Link } from 'react-router-dom';
import './AibIntroduction.css';

const AibIntroduction = ({ setSelectedSection }) => {
  const copyText = (text) => {
    alert(`Copied: ${text}`);
  };

  const sampleCredsExample = `us-east-2
AKIA...
QmS2...`;

  const sampleCreds = `[region_name]
[aws_access_key_id]
[secret_access_key]
...repeated for each region
`;

  const sampleActions = `# get instance ids
[
    {
        "action": "describe_instances",
        "region": "us-east-2",
        "params": {},
        "returnExpr" : """
[
    inst['InstanceId']
    for rsv in res['Reservations']
    for inst in rsv['Instances']
]
""",
    }
]
`;

  const sampleResponse = `[
    [
        "i-0ac8276c3fa940ffe",
        "i-098071177ab312e01",
        "i-0eec87368366c374a",
        "i-067d2b42a432fab31",
        "i-0d36cddcb30d5985e",
        "i-078808c4fda24a8da"
    ]
]`;

  const sampleCommand = `AwsInfraBuilder.bin \\
  /path/to/aws/creds.key \\
  /path/to/aws/actions.json`;

  return (
    <div className="introduction-container">
      <h1>AWS Infrastructure Builder</h1>

      <p className="introduction-text">
        The AWS Infrastructure Builder is an executable tool designed to provision and manage infrastructure through AWS using a JSON-based language consistent with the official AWS EC2 API.
      </p>

      <p className="api-link">
        Here is a reference to official AWS EC2 API: <a href="https://boto3.amazonaws.com/v1/documentation/api/1.9.42/reference/services/ec2.html" target="_blank">AWS EC2 API documentation</a>.
      </p>

      <p className="introduction-text">
        The module also provides a feature to filter the response information for better control over the returned data.
      </p>

      <p className="introduction-text" style={{fontSize: '24px'}}>
        All APIs in the [
        <a href="https://boto3.amazonaws.com/v1/documentation/api/1.9.42/reference/services/ec2.html" target="_blank">
          AWS EC2 API
        </a>
        ] are in <b>snake case</b>,
        but this module also accepts <b>lower camel case</b>.
      </p>

      <h2>Quick Start</h2>
      <ol>
        <li>
          Define AWS credentials for each region:
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleCredsExample}
            onCopy={copyText}
          />
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleCreds}
            onCopy={copyText}
          />
        </li>
        <li>
          Define a JSON document representing the actions to perform,
          Edit the <code>returnExpr</code> field referencing <code>res</code> object to
          format desired result.
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleActions}
            onCopy={copyText}
          />
        </li>
        <li>
          Execute the module with the following command:
          <CopyableCodeSnippet
            codeType="shell"
            codeString={sampleCommand}
            onCopy={copyText}
          />
        </li>
        <li>
          Receive the response:
          <CopyableCodeSnippet
            codeType="json"
            codeString={sampleResponse}
            onCopy={copyText}
          />
        </li>
      </ol>

      <h2>CLI API Reference</h2>
      <p className="api-description">
        The AWS Infra Builder CLI API provides various functionalities for provisioning and managing infrastructure through AWS.
      </p>
      <ul className="api-list">
        <li>
          <span className="api-command">Usage:</span> <CopyableCodeSnippet codeType="shell" codeString="AwsInfraBuilder.bin <access_keys_path> <actions_path>" onCopy={copyText} />
        </li>
      </ul>

    </div>
  );
};

export default AibIntroduction;
